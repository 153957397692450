import './Home.css';
import { useState } from 'react';
import Header from '../Header/Header';
import SampleQRCode from '../../assets/HomePageQRCode.webp';
import Footer from '../Footer/Footer';
import QRCode from 'react-qr-code';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

const Home = () => {
    const [inputvalue, setInputvalue] = useState('');
    const [isValid, setIsValid] = useState(true);
    const [showGeneratedQRCode, setShowGeneratedQRCode] = useState(false);

    const handleTextArea = (e) => {
        setInputvalue(e.target.value);
        setIsValid(true);
        setShowGeneratedQRCode(false);
    }

    const handleGenerateQRCode = () => {
        if(inputvalue){
            setIsValid(true);
            setShowGeneratedQRCode(true);
            setTimeout(() => {
                const element = document.getElementById('scroll_text');
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }, 250)
        } else {
            setIsValid(false)
        }
    }

    const handleReset = () => {
        setShowGeneratedQRCode(false);
        setInputvalue('');
    }

    const handleCreateNowBtn = () => {
        const element = document.getElementById('create_now');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleDownloadQRCode = async(isjpg) => {
        const element = document.getElementById('gen_qr_code');
            try{
                const canvas = await html2canvas(element, { scale: 4.5, width: element.offsetWidth, height: element.offsetHeight});
                const imgData = canvas.toDataURL('image/jpeg');
                    
                if (isjpg) {
                    //   working
                    const link = document.createElement('a');
                    link.href = imgData;
                    link.download = `QR_Code`;
                    link.click();
                }  else {
                    const pdf = new jsPDF(
                        {
                            orientation: 'portrait', 
                            unit: 'mm',              
                            format: [300, 300],           
                        }
                    );
                    pdf.addImage(imgData, 'PNG', 0, 0, 300, 300);
                    pdf.save(`QR_Code`);
                }
            } catch (error) {
                console.error('Error capturing content:', error);
            }
    }

    return(
        <>
            <Header/>
            <main>
                <section className='hmpg_banner center_align'>
                    <div className='center_align' style={{marginBottom: '2rem', flexDirection: 'column'}}>
                        <h1 className='h1_title'>Create free QR code easily in seconds</h1>
                        <p className='title_descp'>Generate QR codes for text, URLs, or links,
                            and download it in HD image or ready to print high-quality PDF file.
                        </p>
                        <div style={{fontWeight: '700'}}> No log-in | No sign-up required</div>
                    </div>
                    <div style={{position: 'relative'}}>
                        <img alt="sample-qr-code" className='smpl_qr_img_sty' src={SampleQRCode}/>
                        <div className="scanner">
                            <div className="scanner-line"></div>
                        </div>

                    </div>
                    <button className='get_start_btn' style={{cursor: 'pointer'}} onClick={handleCreateNowBtn}>
                        Create QR Code Now
                    </button>
                </section>

                <section id="create_now" className='sec_section center_align'>
                    <h2>Generate your QR code now</h2>
                    <div className='textarea_box center_align'>
                        <div className='textarea_wrap'>
                            {
                                showGeneratedQRCode &&
                                <div className='center_align' style={{flexDirection: 'column'}}>
                                    <h2 id="scroll_text" style={{color: '#5bc900'}}><br/>Your QR Code is ready to download!</h2>
                                    <div id="gen_qr_code" style={{background: '#fff', padding: '15px', marginBottom: '1.5rem',width: '300px',height: '300px',boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'}}>
                                        <QRCode fgColor='#000000' bgColor='#ffffff' value={inputvalue} style={{width: '100%', height: '100%'}}></QRCode>
                                    </div>
                                    <div style={{width: '300px'}}>
                                        <button id="img_download"  className='dwld_btn green_clr' onClick={() => handleDownloadQRCode(true)}>Download as HD Image</button>
                                        <button id="pdf_download" className='dwld_btn' onClick={() => handleDownloadQRCode(false)} style={{backgroundColor: '#248ae1'}}>Download as high-quality PDF</button>
                                    </div>
                                </div>
                            }
                            <div className='textarea_title'>Enter the text, website links, or url and click on Generate QR Code:</div>
                            <textarea 
                                className='textarea_sty'
                                value={inputvalue}
                                placeholder='Paste the text or type here...'
                                onChange={handleTextArea}
                            >
                            </textarea>
                            {
                                !isValid &&
                                <div style={{color: 'tomato', fontSize: '14px'}}>This field cannot be empty!</div>
                            }
                            {
                                showGeneratedQRCode &&
                                <button className='get_start_btn' style={{backgroundColor: '#bcbcbc', marginRight: '1rem', cursor: 'pointer'}} onClick={handleReset}>
                                RESET
                                </button>
                            }
                            <button className='get_start_btn green_clr' onClick={handleGenerateQRCode}>
                                Generate QR Code
                            </button>
                        </div>
                    </div>
                </section>
            </main>
            <Footer/>
        </>
    )
}

export default Home;