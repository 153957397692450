import './Footer.css';

const Footer = () => {
    return(
        <footer>
            <div className='footer-copyright'>
                <p>Copyright &copy; 2024 Buildmyqrcode.com. All Rights Reserved.</p>
            </div>
        </footer>
    )
}

export default Footer;