import './Header.css';

const Header = () => {
    return(
        <header>
            <div className='logo-box'>
                <div className='logo-text-upper'>BUILD MY</div>
                <div className='logo-text-lower'>QR CODE</div>
            </div>
        </header>
    )
}

export default Header;